import React, { useRef, useState } from "react";
import { StyledEditPage } from "./StyledEditPage";
import ImagePreview from "../../components/imagePreview/ImagePreview";
import FileUpload from "../../components/input/file/FileUpload";
import { CustomTextField } from "../../components/input/textField/CustomTextField";
import { Divider } from "@mui/material";
import CustomButton from "../../components/input/button/CustomButton";
import useDialogStore from "../../stores/useDialogStore";

import { useNavigate } from "react-router-dom";
import { MarkerType } from "../../types/common/commonTypes";

const EditPage = () => {
  const { openDialog, closeDialog } = useDialogStore();
  const navigate = useNavigate();
  const fileRef = useRef<any>(null);
  const [title, setTitle] = useState("");
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const [markers, setMarkers] = useState<MarkerType[]>([]);

  const onCancelClick = () => {
    openDialog({
      size: "small",
      title: "작성 취소",
      state: "info",
      content: "작성을 취소하고 목록으로 돌아가시겠습니까?",
      button: {
        label: "Okay",
        onClick: () => {
          closeDialog();
          navigate("/");
        },
      },
      secondButton: {
        label: "Cancel",
        onClick: () => closeDialog(),
      },
    });
  };

  return (
    <StyledEditPage>
      <div className="edit-content-wrap">
        <CustomTextField
          id="title"
          value={title}
          label="제목"
          handleChange={setTitle}
        />
        <div className="file-wrap">
          <ImagePreview
            file={uploadedFile}
            markers={markers}
            setMarkers={setMarkers}
            handleClick={() => fileRef.current?.onFileClick()}
          />
          <FileUpload
            ref={fileRef}
            uploadedFile={uploadedFile}
            handleChange={setUploadedFile}
            accept="image/*,application/pdf"
            placeholder="도면 파일을 선택해 주세요."
          />
        </div>
      </div>
      <Divider />
      <div className="edit-button-wrap">
        <CustomButton
          type="outlined"
          color="primary"
          size="large"
          label="Cancel"
          onClick={onCancelClick}
        />
        <CustomButton
          width="100px"
          type="contained"
          color="primary"
          size="large"
          label="Save"
        />
      </div>
    </StyledEditPage>
  );
};

export default EditPage;
