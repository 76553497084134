import styled from "styled-components";

export const StyledMainPage = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  box-sizing: border-box;
  padding: 36px 24px;

  .main-list-paper {
    width: 100%;
    height: calc(100% - 56px);
    .main-list-wrap {
      width: 100%;
      height: 100%;
      overflow: auto;
    }
  }
  .add-btn {
    position: absolute;
    bottom: 16px;
    right: 16px;
  }
`;
