import {
  Divider,
  IconButton,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditIcon from "@mui/icons-material/Edit";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

import React, { ReactNode, useState } from "react";
import MoreMenu from "../../moreMenu/MoreMenu";

type VirtualizedListItemProps = {
  primary: string;
  icon?: ReactNode;
  isLastItem?: boolean;
  secondary?: string;
  observer?: (instance: HTMLDivElement) => void;
};

const MORE_BTN_LIST = [
  { label: "수정", icon: <EditIcon />, onClick: () => console.log("수정") },
  {
    label: "복제",
    icon: <ContentCopyIcon />,
    onClick: () => console.log("복제"),
  },
  {
    label: "삭제",
    icon: <DeleteOutlineIcon />,
    onClick: () => console.log("삭제"),
  },
];

const VirtualizedListItem = ({
  primary,
  icon,
  isLastItem = false,
  secondary,
  observer,
}: VirtualizedListItemProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <ListItemButton ref={observer}>
        {icon && <ListItemIcon>{icon}</ListItemIcon>}
        <ListItemText primary={primary} secondary={secondary} />
        <IconButton
          edge="end"
          aria-label="more"
          onClick={handleClick}
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          sx={open ? { backgroundColor: "#d9dde1" } : undefined}
        >
          <MoreVertIcon />
        </IconButton>
      </ListItemButton>
      <MoreMenu
        id="basic-menu"
        direction="left"
        anchorEl={anchorEl}
        open={open}
        handleClose={handleClose}
        buttonList={MORE_BTN_LIST}
      />
      {!isLastItem && <Divider variant="fullWidth" component="li" />}
    </>
  );
};

export default VirtualizedListItem;
