import React from "react";
import useAuthStore from "../stores/useAuthStore";
import { Navigate, Outlet } from "react-router-dom";
import ErrorPage from "../pages/error/ErrorPage";
import useErrorCodeStore from "../stores/useErrorCodeStore";

const PublicRoute = () => {
  const { isLogin } = useAuthStore();
  const { errorCode } = useErrorCodeStore();

  if (errorCode) return <ErrorPage />;
  else if (isLogin) return <Navigate to="/" />;
  else return <Outlet />;
};

export default PublicRoute;
