import { IconButton, Tooltip } from "@mui/material";
import RoomIcon from "@mui/icons-material/Room";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import React from "react";
import MoreMenu from "../../moreMenu/MoreMenu";
import { MarkerType } from "../../../types/common/commonTypes";

type MarkerProps = {
  x: number;
  y: number;
  marker: MarkerType;
  isSelected: boolean;
  anchorEl: HTMLElement | null;
  togglePopup: (isOpen: boolean) => void;
  handleMenuOpen: (e: React.MouseEvent<HTMLButtonElement>) => void;
  handleMenuClose: () => void;
  onMarkerDelete: () => void;
};

const Marker = ({
  x,
  y,
  marker,
  isSelected,
  anchorEl,
  togglePopup,
  handleMenuOpen,
  handleMenuClose,
  onMarkerDelete,
}: MarkerProps) => {
  return (
    <>
      <Tooltip
        title={`타설시간: ${marker.data.pouringTime}`}
        arrow
        placement="top"
      >
        <IconButton
          className="marker-icon-btn"
          sx={{
            left: `${x}px`,
            top: `${y}px`,
            backgroundColor: isSelected && anchorEl ? "#d9dde1" : undefined,
          }}
          edge="end"
          aria-label="more"
          onClick={(e) => handleMenuOpen(e)}
          aria-controls={isSelected && anchorEl ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={isSelected && anchorEl ? "true" : undefined}
        >
          <RoomIcon
            className="marker-icon"
            sx={{
              color: "secondary.main", // 아이콘 색상 (MUI 테마 사용)
              stroke: "#000000", // 아이콘 테두리
              strokeWidth: 1,
            }}
          />
        </IconButton>
      </Tooltip>
      <MoreMenu
        id="basic-menu"
        direction="left"
        anchorEl={anchorEl}
        open={anchorEl !== null && isSelected}
        handleClose={handleMenuClose}
        buttonList={[
          {
            label: "수정",
            icon: <EditIcon />,
            onClick: () => togglePopup(true),
          },
          {
            label: "삭제",
            icon: <DeleteOutlineIcon />,
            onClick: () => onMarkerDelete(),
          },
        ]}
      />
    </>
  );
};

export default Marker;
