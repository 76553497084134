import styled from "styled-components";

export const StyledEditPage = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;

  .edit-content-wrap {
    width: 100%;
    height: calc(100% - 64px);
    padding: 36px 24px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    overflow: auto;
    gap: 16px;

    .file-wrap {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 12px;
    }
  }

  .edit-button-wrap {
    box-sizing: border-box;
    width: 100%;
    height: 64px;
    padding: 12px 24px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
    background-color: #ffffff;
  }
`;
