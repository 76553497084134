import { ReactNode } from "react";
import { create } from "zustand";

export type ButtonProps = {
  label?: string;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  disabled?: boolean;
  width?: string;
  onClick?: (e?: React.MouseEvent<HTMLButtonElement>) => Promise<any> | void;
};

export interface AlertDialogForm {
  size: "small" | "large";
  state: "info" | "success" | "warning" | "error";
  title: string;
  content: string;
  caption?: { content: string; icon?: ReactNode };
  disabled?: boolean;
  preventClose?: boolean;
}

export interface ConfirmDialogForm extends AlertDialogForm {
  button?: ButtonProps;
  secondButton?: ButtonProps;
}

export interface DialogStoreType {
  dialogForm: ConfirmDialogForm | null;
  isDialogVisible: boolean;
  openDialog: (form: ConfirmDialogForm | null) => void;
  closeDialog: () => void;
}

const useDialogStore = create<DialogStoreType>((set) => ({
  dialogForm: null,
  isDialogVisible: false,
  openDialog: (form: ConfirmDialogForm | null) =>
    set({ dialogForm: form, isDialogVisible: true }),
  closeDialog: () => set({ dialogForm: null, isDialogVisible: false }),
}));

export default useDialogStore;
