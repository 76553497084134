import React, { useEffect, useRef } from "react";
import { CookiesProvider } from "react-cookie";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Layout from "./components/layout/Layout";
import MainPage from "./pages/main/MainPage";
import LoginPage from "./pages/login/LoginPage";
import PrivateRoute from "./routes/PrivateRoute";
import useAuthStore from "./stores/useAuthStore";
import PublicRoute from "./routes/PublicRoute";
import CustomAlert from "./components/alert/CustomAlert";
import CustomDialog from "./components/dialog/CustomDialog";
import Theme from "./styles/theme/Theme";
import EditPage from "./pages/edit/EditPage";

function App() {
  const appRef = useRef<HTMLDivElement>(null);
  const { isLogin } = useAuthStore();

  const redefineHeight = () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  };

  useEffect(() => {
    // 모바일 화면의 높이를 맞추기 위해 이벤트 등록 및 초기화 함수 실행
    redefineHeight();
    window.addEventListener("resize", redefineHeight);

    return () => {
      window.removeEventListener("resize", redefineHeight);
    };
  }, []);

  return (
    <CookiesProvider>
      <BrowserRouter>
        <Theme>
          <div className="App" ref={appRef}>
            <Layout>
              <Routes>
                {/** 로그인하지 않았을 경우 */}
                <Route element={<PrivateRoute />}>
                  <Route path="/" element={<MainPage />} />
                  <Route path="/edit" element={<EditPage />} />
                </Route>

                {/** 로그인한 경우 */}
                <Route element={<PublicRoute />}>
                  <Route path="/login" element={<LoginPage />} />
                </Route>

                {/** 지정되지 않은 URL 진입 시 리다이렉트 */}
                <Route
                  path="/*"
                  element={<Navigate to={isLogin ? "/" : "/login"} />}
                />
              </Routes>
            </Layout>

            <CustomAlert />
            <CustomDialog />
          </div>
        </Theme>
      </BrowserRouter>
    </CookiesProvider>
  );
}

export default App;
