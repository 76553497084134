import React, { ReactElement } from "react";
import { Navigate, Outlet } from "react-router-dom";
import useAuthStore from "../stores/useAuthStore";
import Error401 from "../pages/error/pages/Error401";
import useErrorCodeStore from "../stores/useErrorCodeStore";
import ErrorPage from "../pages/error/ErrorPage";

export default function PrivateRoute(): ReactElement {
  const { isLogin } = useAuthStore();
  const { errorCode } = useErrorCodeStore();

  if (errorCode) return <ErrorPage />;
  else if (isLogin) return <Outlet />;
  else return <Navigate to="/login" />;
}
