import React, { forwardRef, useImperativeHandle, useRef } from "react";
import { Button } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import styled from "styled-components";
import { CustomTextField } from "../textField/CustomTextField";
import { StyledFileUpload } from "./StyledFileUpload";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

type FileUploadProps = {
  uploadedFile: File | null;
  placeholder?: string;
  accept?: string;
  handleChange: (files: File | null) => void;
};

const FileUpload = forwardRef<any, FileUploadProps>(
  (
    {
      uploadedFile,
      placeholder = "파일을 선택해 주세요.",
      accept,
      handleChange,
    },
    ref
  ) => {
    const fileRef = useRef<HTMLInputElement>(null);
    useImperativeHandle(ref, () => ({
      onFileClick() {
        fileRef.current?.click();
      },
    }));

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.files) handleChange(event.target.files[0]);
      if (fileRef.current) fileRef.current.value = "";
    };

    const handleDeleteClick = () => {
      handleChange(null);
    };

    return (
      <StyledFileUpload>
        <CustomTextField
          width="calc(100% - 8rem)"
          id="uploaded-file"
          placeholder={placeholder}
          value={uploadedFile?.name ?? ""}
          readonly
          button={
            uploadedFile
              ? { icon: <HighlightOffIcon />, onClick: handleDeleteClick }
              : undefined
          }
        />
        <Button
          component="label"
          size="medium"
          role={undefined}
          variant="contained"
          tabIndex={-1}
          startIcon={<CloudUploadIcon />}
        >
          Upload
          <VisuallyHiddenInput
            ref={fileRef}
            type="file"
            accept={accept}
            onChange={onChange}
          />
        </Button>
      </StyledFileUpload>
    );
  }
);

export default FileUpload;
