import React from "react";
import { StyledLayout } from "./StyledLayout";
import Header from "./header/Header";

interface LayoutProps {
  children: React.ReactNode;
}

const Layout = ({ children }: LayoutProps) => {
  return (
    <StyledLayout>
      <Header />
      <div className="main-area">{children}</div>
    </StyledLayout>
  );
};

export default Layout;
