import styled from "styled-components";

export const StyledSearchArea = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding-bottom: 16px;

  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 4px;
`;
