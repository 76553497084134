import React, { useState } from "react";
import SearchField from "../../../components/input/search/SearchField";
import { StyledSearchArea } from "./StyeldSearchArea";

const SearchArea = () => {
  return (
    <StyledSearchArea>
      <SearchField
        optionList={[
          { value: "location", label: "위치" },
          { value: "title", label: "제목" },
        ]}
        defaultOption="location"
        onSearch={(value) => console.log(value)}
      />
    </StyledSearchArea>
  );
};

export default SearchArea;
