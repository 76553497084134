import { List, ListItem } from "@mui/material";
import React, { useCallback, useRef, useState } from "react";
import VirtualizedListItem from "./listItem/VirtualizedListItem";
import SkeletonItem from "./SkeletonItem.tsx/SkeletonItem";

import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";

const VirtualizedList = () => {
  const [items, setItems] = useState(
    Array.from({ length: 20 }, (_, i) => `Item ${i + 1}`)
  );
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  const observer = useRef<IntersectionObserver | null>(null);
  const lastItemRef = useCallback(
    (node: HTMLDivElement) => {
      if (loading) return;

      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          loadMoreItems();
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  const loadMoreItems = () => {
    setLoading(true);
    setTimeout(() => {
      setItems((prev) => [
        ...prev,
        ...Array.from({ length: 10 }, (_, i) => `Item ${prev.length + i + 1}`),
      ]);
      setLoading(false);

      // 무한 스크롤 종료 조건 예시
      if (items.length >= 100) setHasMore(false);
    }, 1500);
  };

  return (
    <List>
      {items.map((item, index) => (
        <VirtualizedListItem
          key={item}
          icon={<InsertDriveFileIcon />}
          primary={item}
          secondary={item}
          observer={index === items.length - 1 ? lastItemRef : undefined}
          isLastItem={!loading && index === items.length - 1}
        />
      ))}
      {loading && (
        <ListItem>
          <SkeletonItem variantList={["h3", "body1"]} />
        </ListItem>
      )}
    </List>
  );
};

export default VirtualizedList;
