import { createTheme, ThemeProvider } from "@mui/material";
import React, { ReactNode } from "react";

const theme = createTheme({
  palette: {
    primary: {
      light: "#8fbcd4", // 부드럽고 어두운 하늘색
      main: "#497d9e", // 세련된 어두운 파란색
      dark: "#2d5a75", // 깊은 어두운 청색
    },
    secondary: {
      light: "#d9dde1", // 밝은 중성 회색
      main: "#a3adb8", // 세련된 어두운 회색
      dark: "#6e7a85", // 깊이 있는 어두운 회색
    },
    background: {
      default: "#f7f9fc", // 밝고 깨끗한 기본 배경
      paper: "#ffffff", // 카드 및 섹션 배경
    },
    text: {
      primary: "#4f5d75", // 부드럽고 세련된 블루-그레이
      secondary: "#8a9ba8", // 밝은 회색-블루 텍스트
    },
  },
  typography: {
    fontFamily: "Pretendard, sans-serif", // 기본 폰트 설정
  },
});

type ThemeProps = {
  children: ReactNode;
};

const Theme = ({ children }: ThemeProps) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default Theme;
