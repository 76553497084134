import { Skeleton, Typography } from "@mui/material";
import { Variant } from "@mui/material/styles/createTypography";
import React from "react";
import { StyledSkeletonItem } from "./StyledSkeletonItem";

type SkeletonItemProps = {
  variantList: Variant[];
};

const SkeletonItem = ({ variantList }: SkeletonItemProps) => {
  return (
    <StyledSkeletonItem>
      {variantList.map((variant, index) => (
        <Typography component="div" key={variant + index} variant={variant}>
          <Skeleton />
        </Typography>
      ))}
    </StyledSkeletonItem>
  );
};

export default SkeletonItem;
