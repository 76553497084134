import React, { ReactNode, useEffect, useRef, useState } from "react";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";

type CustomSelectProps = {
  id: string;
  value: string;
  optionList: { label: string; value: string }[];
  error?: { isError: boolean; errorMessage?: string };
  width?: string;
  maxHeight?: string;
  label?: string;
  placeholder?: string;
  helpMessage?: string;
  disabled?: boolean;
  readonly?: boolean;
  required?: boolean;
  handleChange?: (value: string) => void;
  onKeyDown?: (e?: React.KeyboardEvent) => void;
};

export const CustomSelect = ({
  id,
  value,
  optionList = [],
  error = { isError: false, errorMessage: "" },
  width,
  maxHeight,
  label,
  placeholder,
  helpMessage,
  disabled = false,
  readonly = false,
  required = false,
  handleChange,
}: CustomSelectProps) => {
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleValueChange = (e: SelectChangeEvent) => {
    const inputValue = e.target.value;

    if (handleChange) {
      handleChange(inputValue);
    }
  };

  useEffect(() => {
    setIsError(error.isError);
    setErrorMessage(error.errorMessage ?? "");
  }, [error]);

  return (
    <FormControl
      required={required}
      disabled={disabled}
      error={error.isError}
      size="small"
      sx={{
        minWidth: "80px",
        backgroundColor: "#FFFFF",
        width: width ?? undefined,
      }}
    >
      {label && <InputLabel id={`${id}-label`}>{label}</InputLabel>}
      <Select
        labelId={label ? `${id}-label` : undefined}
        id={id}
        autoWidth
        value={value}
        label={label}
        onChange={handleValueChange}
        inputProps={{ readOnly: readonly }}
        placeholder={placeholder}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: maxHeight ?? "240px",
              minWidth: width ?? "80px",
            },
          },
        }}
        sx={{ backgroundColor: "white" }}
      >
        {optionList.map((item, index) => (
          <MenuItem key={index} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
      {isError ? (
        <FormHelperText>{errorMessage}</FormHelperText>
      ) : helpMessage ? (
        <FormHelperText>{helpMessage}</FormHelperText>
      ) : null}
    </FormControl>
  );
};
