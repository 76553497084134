import React, { useEffect } from "react";
import useAuth from "../../../hooks/useAuth";
import useErrorCodeStore from "../../../stores/useErrorCodeStore";
import ErrorNotice from "../../../components/error/ErrorNotice";

const Error401 = () => {
  const { logout } = useAuth();
  const { setErrorCode } = useErrorCodeStore();
  useEffect(() => {
    logout(false);

    return () => setErrorCode(undefined);
  }, []);

  return (
    <ErrorNotice
      errorCode={401}
      errorMessage="Unauthorized Access"
      guideMessage="인증이 만료되었습니다. 다시 로그인해 주세요."
      buttonLabel="Login Again"
    />
  );
};

export default Error401;
