import { Fab, Tooltip } from "@mui/material";
import React, { ReactNode, useMemo } from "react";

type CustomFloatingButtonProps = {
  icon: ReactNode;
  size: "small" | "medium" | "large";
  color: "primary" | "secondary" | "success" | "error" | "info" | "warning";
  label?: string;
  className?: string;
  disabled?: boolean;
  tooltip?: {
    label: string;
    direction:
      | "bottom"
      | "left"
      | "right"
      | "top"
      | "bottom-end"
      | "bottom-start"
      | "left-end"
      | "left-start"
      | "right-end"
      | "right-start"
      | "top-end"
      | "top-start";
  };
  onClick: (e: React.MouseEvent) => void;
};

const CustomFloatingButton = ({
  icon,
  label,
  className,
  size,
  color,
  onClick,
  tooltip,
}: CustomFloatingButtonProps) => {
  const fab = useMemo(() => {
    return (
      <Fab
        className={className}
        size={size}
        color={color}
        aria-label="label"
        variant={label ? "extended" : "circular"}
        onClick={onClick}
      >
        {icon}
        {label && label}
      </Fab>
    );
  }, [icon, onClick, tooltip]);

  return tooltip ? (
    <Tooltip title={tooltip.label} placement={tooltip.direction} arrow>
      {fab}
    </Tooltip>
  ) : (
    fab
  );
};

export default CustomFloatingButton;
