import React from "react";
import { StyledMainPage } from "./StyledMainPage";
import CustomFloatingButton from "../../components/input/button/CustumFloatingButton/CustomFloatingButton";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import VirtualizedList from "../../components/virtualizedList/VirtualizedList";
import { Paper } from "@mui/material";
import SearchArea from "./searchArea/SearchArea";
import { useNavigate } from "react-router-dom";

const MainPage = () => {
  const navigate = useNavigate();

  return (
    <StyledMainPage>
      <SearchArea />
      <Paper
        variant="elevation"
        square={false}
        elevation={3}
        className="main-list-paper"
      >
        <div className="main-list-wrap">
          <VirtualizedList />
        </div>
      </Paper>
      <CustomFloatingButton
        className="add-btn"
        icon={<AddOutlinedIcon />}
        size="large"
        color="primary"
        onClick={() => navigate("/edit")}
      />
    </StyledMainPage>
  );
};

export default MainPage;
