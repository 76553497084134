import styled from "styled-components";

export const StyledLayout = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  > .main-area {
    width: 100%;
    height: calc(100% - 56px);
  }
`;
