import { Box, Button, Typography } from "@mui/material";
import React from "react";

type ErrorNoticeProps = {
  errorCode: number;
  errorMessage: string;
  guideMessage: string;
  buttonLabel: string;
};

const ErrorNotice = ({
  errorCode,
  errorMessage,
  guideMessage,
  buttonLabel,
}: ErrorNoticeProps) => {
  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        bgcolor: "#f7f9fc", // 배경색
        textAlign: "center",
        px: 3,
      }}
    >
      <Typography
        variant="h1"
        sx={{ fontSize: "5rem", fontWeight: "bold", color: "#1565c0" }}
      >
        {errorCode}
      </Typography>
      <Typography variant="h5" sx={{ color: "#555", mb: 2 }}>
        {errorMessage}
      </Typography>
      <Typography variant="body1" sx={{ color: "#777", mb: 4 }}>
        {guideMessage}
      </Typography>
      <Button
        variant="contained"
        color="primary"
        size="large"
        sx={{ textTransform: "none", px: 4, py: 1 }}
        onClick={() => window.location.reload()}
      >
        {buttonLabel}
      </Button>
    </Box>
  );
};

export default ErrorNotice;
