import React, { ReactNode, useEffect, useRef, useState } from "react";
import { Icon, IconButton, InputAdornment, TextField } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

type CustomTextFieldProps = {
  id: string;
  value: string;
  error?: { isError: boolean; errorMessage?: string };
  width?: string;
  icon?: ReactNode;
  button?: { icon: ReactNode; onClick: () => void };
  type?: "number" | "text" | "password";
  label?: string;
  placeholder?: string;
  minLength?: number;
  maxLength?: number;
  helpMessage?: string;
  disabled?: boolean;
  readonly?: boolean;
  required?: boolean;
  handleChange?: (value: string) => void;
  onKeyDown?: (e?: React.KeyboardEvent) => void;
};

export const CustomTextField = ({
  id,
  value,
  error = { isError: false, errorMessage: "" },
  width,
  icon,
  button,
  type = "text",
  label,
  placeholder,
  minLength,
  maxLength,
  helpMessage,
  disabled = false,
  readonly = false,
  required = false,
  handleChange,
  onKeyDown,
}: CustomTextFieldProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const checkLength = (inputValue: string) => {
    let error = false;
    if (minLength !== undefined) {
      error = inputValue.length < minLength;
      setIsError(error);
      setErrorMessage(error ? `${minLength}자 이상으로 입력해 주세요.` : "");
      return;
    }
    if (maxLength !== undefined) {
      error = inputValue.length > maxLength;
      setIsError(inputValue.length > maxLength);
      setErrorMessage(error ? `${maxLength}자 이하로 입력해 주세요.` : "");
    }
  };

  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;

    if (handleChange) {
      handleChange(inputValue);
      checkLength(inputValue);
    }
  };

  const handleClickShowPassword = () => {
    setIsShowPassword(!isShowPassword);
  };

  useEffect(() => {
    setIsError(error.isError);
    setErrorMessage(error.errorMessage ?? "");
  }, [error]);

  return (
    <TextField
      ref={inputRef}
      size="small"
      id={id}
      label={label}
      sx={{ width: width ? width : "100%" }}
      placeholder={placeholder}
      value={value}
      onChange={handleValueChange}
      onKeyDown={onKeyDown}
      type={isShowPassword ? "text" : type}
      required={required}
      disabled={disabled}
      helperText={isError ? errorMessage : helpMessage}
      error={isError}
      slotProps={{
        input: {
          sx: { backgroundColor: "#FFF" },
          startAdornment: icon ? (
            <InputAdornment position="start">{icon}</InputAdornment>
          ) : undefined,
          endAdornment:
            type === "password" ? (
              <IconButton
                aria-label={
                  isShowPassword ? "hide the password" : "display the password"
                }
                onClick={handleClickShowPassword}
                edge="end"
              >
                {isShowPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            ) : button ? (
              <IconButton onClick={button.onClick} edge="end">
                {button.icon}
              </IconButton>
            ) : undefined,
        },
        htmlInput: {
          maxLength: maxLength,
          minLength: minLength,
          readOnly: readonly,
        },
      }}
    />
  );
};
