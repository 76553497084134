import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React from "react";
import useDialogStore from "../../stores/useDialogStore";
import CustomButton from "../input/button/CustomButton";

const CustomDialog = () => {
  const { dialogForm, isDialogVisible, closeDialog } = useDialogStore();

  return dialogForm ? (
    <Dialog
      onClose={closeDialog}
      open={isDialogVisible}
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle>{dialogForm.title}</DialogTitle>
      <DialogContent
        sx={{
          minHeight: "80px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {dialogForm.content}
      </DialogContent>
      <DialogActions>
        {dialogForm.button && (
          <CustomButton
            label={dialogForm.button.label}
            onClick={dialogForm?.button.onClick}
            startIcon={dialogForm.button.startIcon}
            endIcon={dialogForm.button.endIcon}
            type="contained"
            color="primary"
            size="small"
          />
        )}
        {dialogForm.secondButton && (
          <CustomButton
            label={dialogForm.secondButton.label}
            onClick={dialogForm.secondButton.onClick}
            startIcon={dialogForm.secondButton.startIcon}
            endIcon={dialogForm.secondButton.endIcon}
            type="outlined"
            color="primary"
            size="small"
          />
        )}
      </DialogActions>
    </Dialog>
  ) : null;
};

export default CustomDialog;
