import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import React from "react";

export type TableRowType = {
  name: string;
  key: string;
  width?: number;
  rowRender: React.ReactNode;
  required?: boolean;
};

type CustomTableProps = {
  rows: TableRowType[];
};

const CustomTable = ({ rows }: CustomTableProps) => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.key}
              sx={{
                "&:last-child td, &:last-child th": {
                  border: 0,
                  borderRight: "1px solid rgba(224, 224, 224, 1)",
                },
              }}
            >
              <TableCell
                component="th"
                scope="row"
                width={row.width}
                sx={{
                  paddingX: "12px",
                  paddingY: "8px",
                  borderRight: "1px solid rgba(224, 224, 224, 1)",
                }}
              >
                {row.name}{" "}
                {row.required && <span style={{ color: "red" }}>*</span>}
              </TableCell>
              <TableCell align="left" sx={{ padding: "8px" }}>
                {row.rowRender}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CustomTable;
