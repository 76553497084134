import styled from "styled-components";

export const StyledImagePreview = styled.div`
  width: 100%;
  height: 400px;
  position: relative;
  cursor: pointer;

  .image-edit-btn {
    position: absolute;
    z-index: 20;
    bottom: 16px;
    right: 16px;
  }

  .layer {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;

    .marker-icon-btn {
      position: absolute;
      border-radius: 50%;
      transform: translate(-50%, -50%);
      z-index: 30;

      .marker-icon {
        width: 5vw;
        height: 5vw;
      }

      @media (max-width: 768px) {
        .marker-icon {
          width: 5vh;
          height: 5vh;
        }
      }
    }
  }

  .image-box {
    width: 100%;
    height: 100%;
    background-color: #e0e0e0;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 50%;
  }
  .wh-100 {
    width: 100%;
    height: 100%;
  }
  .placeholder-text {
    width: 100%;
    height: 100%;
    background-color: #e0e0e0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .pdf-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: #e0e0e0;
  }
`;
