import React, { useEffect, useState } from "react";
import { StyledCustomAlert } from "./StyledCustomAlert";
import { Alert } from "@mui/material";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import "./animation.css";
import useAlertStore, { AlertButtonType } from "../../stores/useAlertStore";
import CustomButton from "../input/button/CustomButton";

export type AlertType = {
  id: string;
  message: string;
  timeout: number;
  type: "success" | "info" | "warning" | "error";
  visible: boolean;
  button?: AlertButtonType;
};

const CustomAlert = () => {
  const {
    alertButton,
    alertCloseTrigger,
    alertInstantClose,
    alertMessage,
    alertTimeout,
    alertType,
    alertVisible,
    resetAlert,
  } = useAlertStore();
  const [alertList, setAlertList] = useState<AlertType[]>([]);

  const alertClose = (id: string) => {
    setAlertList((prevAlertList) => {
      return prevAlertList.map((item) => {
        if (item.id === id) {
          return { ...item, visible: false };
        } else return { ...item };
      });
    });
    setTimeout(() => setAlertList([]), 500);
  };

  useEffect(() => {
    const alertId = String(Math.random());
    const alertObject: AlertType = {
      id: alertId,
      message: alertMessage,
      timeout: alertTimeout,
      type: alertType,
      button: alertButton,
      visible: alertVisible,
    };
    if (alertVisible) {
      setAlertList((prevAlertList) => {
        setTimeout(() => {
          setAlertList((prevAlertList) => {
            return prevAlertList.map((item) => {
              if (item.id === alertId) {
                item = { ...item, visible: false };
              }
              return item;
            });
          });
        }, alertTimeout);
        return [alertObject, ...prevAlertList];
      });
      resetAlert();
    }
  }, [alertMessage, alertTimeout, alertType, alertVisible]);

  useEffect(() => {
    if (alertList.length) {
      if (!alertList.some((item) => item.visible)) {
        setTimeout(() => setAlertList([]), 500);
      }
    }
  }, [alertList]);

  useEffect(() => {
    if (alertInstantClose) {
      setAlertList([]);
    } else {
      setAlertList(alertList.map((item) => ({ ...item, visible: false })));
    }
  }, [alertCloseTrigger, alertInstantClose]);

  return (
    <TransitionGroup>
      {alertList.map((alert, index) => (
        <CSSTransition
          key={alert.id}
          in={alert.visible}
          timeout={500}
          mountOnEnter
          unmountOnExit
          classNames="bottom-toast-item"
        >
          <StyledCustomAlert
            className={`${index === 0 ? "" : "bottom-fade-item-enter-active"}`}
          >
            <Alert
              severity={alert.type}
              action={
                alert.button && (
                  <CustomButton
                    size="small"
                    type="contained"
                    color="primary"
                    label={alert.button.label}
                    onClick={alert.button.onClick}
                    startIcon={alert.button.icon}
                  />
                )
              }
            >
              {alert.message}
            </Alert>
          </StyledCustomAlert>
        </CSSTransition>
      ))}
    </TransitionGroup>
  );
};

export default CustomAlert;
