import React from "react";
import { AppBar, Toolbar, Typography } from "@mui/material";
import PinDropIcon from "@mui/icons-material/PinDrop";
import useAuthStore from "../../../stores/useAuthStore";
import CustomButton from "../../input/button/CustomButton";
import LogoutIcon from "@mui/icons-material/Logout";
import useDialogStore from "../../../stores/useDialogStore";
import useAuth from "../../../hooks/useAuth";

import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useLocation, useNavigate } from "react-router-dom";

const Header = () => {
  const pathname = useLocation().pathname;
  const navigate = useNavigate();
  const { logout } = useAuth();
  const { openDialog, closeDialog } = useDialogStore();
  const { isLogin } = useAuthStore();

  const onClickLogoutButton = () => {
    openDialog({
      size: "small",
      title: "Logout",
      state: "info",
      content: "로그아웃 하시겠습니까?",
      button: {
        label: "Okay",
        onClick: () => logout(),
      },
      secondButton: {
        label: "Cancel",
        onClick: closeDialog,
      },
    });
  };

  return (
    <AppBar position="static">
      <Toolbar>
        {pathname !== "/login" && pathname !== "/" && (
          <CustomButton
            type="text"
            color="inherit"
            size="small"
            startIcon={<ArrowBackIosNewIcon />}
            onClick={() => navigate(-1)}
          />
        )}
        <PinDropIcon fontSize="large" sx={{ marginRight: "0.8rem" }} />
        <Typography
          variant="h5"
          component="div"
          sx={{ flexGrow: 1, fontWeight: 600, cursor: "pointer" }}
          onClick={() => navigate("/")}
        >
          추정계
        </Typography>

        {isLogin && (
          <CustomButton
            label="Logout"
            type="text"
            color="inherit"
            size="small"
            startIcon={<LogoutIcon />}
            onClick={onClickLogoutButton}
          />
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Header;
