import styled from "@emotion/styled";

export const StyledCustomAlert = styled.div`
  width: max-content;
  max-width: 90%;
  position: fixed;
  transform: translateX(-50%);
  left: 50%;
  bottom: 6.4rem;

  .MuiAlert-message {
    font-size: 1.2rem !important;
  }

  .bottom-toast-item-enter {
    bottom: -10rem;
  }
  .bottom-toast-item-enter-active {
    bottom: 1.4rem;
  }
  .bottom-toast-item-enter-done {
    bottom: 1.4rem;
  }
  .bottom-toast-item-exit {
    bottom: 1.4rem;
  }
  .bottom-toast-item-exit-active {
    bottom: -10rem;
  }
  .bottom-toast-item-exit-done {
    bottom: -10rem;
  }
`;
