import React from "react";
import useErrorCodeStore from "../../stores/useErrorCodeStore";
import Error401 from "./pages/Error401";

const ErrorPage = () => {
  const { errorCode } = useErrorCodeStore();
  if (errorCode === 401) return <Error401 />;
  else return null;
};

export default ErrorPage;
