import { LoginReqType } from "../types/api/userApiType";
import { checkErrorType } from "../utils/apiUtils";
import axiosInstance from "./axiosInstance";

export const requestLogin = async (loginInfo: LoginReqType) => {
  try {
    const response = await axiosInstance.post("/user/login", loginInfo);
    return { ...response.data, isSuccess: true };
  } catch (e: any) {
    checkErrorType(e.config.url, e.response?.status);
    return { isSuccess: false };
  }
};
