import React, { useState } from "react";
import { CustomSelect } from "../select/CustomSelect";
import { CustomTextField } from "../textField/CustomTextField";
import { StyledSearchField } from "./StyeldSearchField";
import {
  SearchOptionType,
  SelectOptionType,
} from "../../../types/common/commonTypes";

type SearchFieldProps = {
  optionList: SelectOptionType[];
  width?: string;
  optionWidth?: string;
  defaultOption?: string;
  defaultValue?: string;
  onSearch: (searchOption: SearchOptionType) => void;
  onKeyDown?: (e?: React.KeyboardEvent) => void;
};

const SearchField = ({
  optionList,
  width,
  optionWidth,
  defaultOption,
  defaultValue,
  onSearch,
  onKeyDown,
}: SearchFieldProps) => {
  const [searchCondition, setSearchCondition] = useState<SearchOptionType>({
    option: defaultOption ?? "",
    value: defaultValue ?? "",
  });

  const onChangeOption = (value: string) => {
    setSearchCondition({ option: value, value: searchCondition.value });
    onSearch({ option: value, value: searchCondition.value });
  };
  const onChangeValue = (value: string) => {
    setSearchCondition({ option: searchCondition.option, value: value });
    onSearch({ option: searchCondition.option, value: value });
  };

  return (
    <StyledSearchField style={{ width: width ?? "100%" }}>
      <CustomSelect
        id="list-search-option"
        value={searchCondition.option}
        optionList={optionList}
        width={optionWidth}
        handleChange={onChangeOption}
      />
      <CustomTextField
        id="list-search-value"
        value={searchCondition.value}
        handleChange={onChangeValue}
        onKeyDown={onKeyDown}
      />
    </StyledSearchField>
  );
};

export default SearchField;
