import React, { Fragment, useEffect, useMemo, useState } from "react";
import { StyledDetailPopup } from "./StyledDetailPopup";
import { CustomTextField } from "../../input/textField/CustomTextField";
import { CustomSelect } from "../../input/select/CustomSelect";
import CustomTable from "../../table/CustomTable";
import { DialogActions, DialogContent, Typography } from "@mui/material";
import CustomButton from "../../input/button/CustomButton";

import CustomTimePicker from "../../input/timePicker/CustomTimePicker";
import { MarkerDataType } from "../../../types/common/commonTypes";
import dayjs from "dayjs";
import { conversationFormula } from "../../../utils/ConversionFormula";

type DetailPopupProps = {
  selectedMarker: MarkerDataType | undefined;
  onSave: (markerData: MarkerDataType) => void;
  onCancel: () => void;
};

const DEVICE_OPTION_LIST = [
  { label: "하이브리드", value: "hybrid" },
  { label: "스트렝스", value: "strength" },
];

const DetailPopup = ({
  selectedMarker,
  onSave,
  onCancel,
}: DetailPopupProps) => {
  const [deviceType, setDeviceType] = useState<"hybrid" | "strength">("hybrid");
  const [measureType, setMeasureType] = useState<"settingDegree" | "strength">(
    "settingDegree"
  );
  const [measuredValues, setMeasuredValues] = useState({
    pouringTime: dayjs(new Date()).format("YYYY-MM-DD"),
    concreteTemperature: "",
    concreteStrength: "",
    convertedMPa: 0,
  });

  const handleValueChange = (
    key: keyof MarkerDataType,
    value: string | null
  ) => {
    if (value !== null) setMeasuredValues({ ...measuredValues, [key]: value });
  };

  const handleTypeChange = (value: "hybrid" | "strength") => {
    setDeviceType(value);
    if (value === "strength") setMeasureType("strength");
  };

  const measureOptionList = useMemo(() => {
    if (measureType === "strength")
      return [{ label: "강도", value: "strength" }];
    else
      return [
        { label: "응결", value: "settingDegree" },
        { label: "강도", value: "strength" },
      ];
  }, [measureType]);

  const handleConvertBtnClick = () => {
    const { concreteStrength } = measuredValues;

    const result = conversationFormula(
      Number(concreteStrength),
      deviceType,
      measureType
    );

    setMeasuredValues({ ...measuredValues, convertedMPa: result });
  };

  const rows = useMemo(() => {
    return [
      {
        name: "현재 시간",
        width: 80,
        key: "measureDate",
        rowRender: (
          <Typography
            variant="body1"
            sx={{ height: "40px", lineHeight: "45px" }}
          >
            {dayjs(new Date()).format("YYYY-MM-DD hh:mm")}
          </Typography>
        ),
      },
      {
        name: "타설 시간",
        key: "pouringTime",
        rowRender: (
          <CustomTimePicker
            id="pouringTime"
            width="130px"
            value={measuredValues.pouringTime}
            handleChange={(value) => handleValueChange("pouringTime", value)}
          />
        ),
      },
      {
        name: "콘트리트 온도",
        key: "concreteTemperature",
        rowRender: (
          <CustomTextField
            id="concreteTemperature"
            placeholder="온도"
            value={measuredValues.concreteTemperature}
            type="number"
            handleChange={(value) =>
              handleValueChange("concreteTemperature", value)
            }
          />
        ),
      },
      {
        name: "경도",
        key: "concreteStrength",
        rowRender: (
          <CustomTextField
            id="concreteStrength"
            placeholder="경도"
            value={measuredValues.concreteStrength}
            type="number"
            handleChange={(value) =>
              handleValueChange("concreteStrength", value)
            }
          />
        ),
      },
      {
        name: "MPa 변환치",
        key: "convertedMPa",
        rowRender: (
          <div className="converted-MPa">
            <Typography
              variant="body1"
              sx={{ height: "40px", lineHeight: "45px" }}
            >
              {measuredValues.convertedMPa}
            </Typography>
            <CustomButton
              type="contained"
              color="primary"
              size="small"
              label="변환"
              onClick={handleConvertBtnClick}
            />
          </div>
        ),
      },
    ];
  }, [measuredValues]);

  useEffect(() => {
    if (selectedMarker) setMeasuredValues(selectedMarker);
  }, []);

  return (
    <>
      <DialogContent dividers sx={{ backgroundColor: "#f0f0f0" }}>
        <StyledDetailPopup>
          <div className="detail-title-area">
            <Typography variant="h6">상세 정보</Typography>
            <div className="type-wrap">
              <CustomSelect
                id="tool-type"
                width="120px"
                label="기기 유형"
                value={deviceType}
                handleChange={(value) =>
                  handleTypeChange(value as "hybrid" | "strength")
                }
                optionList={DEVICE_OPTION_LIST}
              />
              <CustomSelect
                id="measure-type"
                width="100px"
                label="측정 범위"
                value={measureType}
                handleChange={(value) =>
                  setMeasureType(value as "settingDegree" | "strength")
                }
                optionList={measureOptionList}
              />
            </div>
          </div>
          <CustomTable rows={rows} />
        </StyledDetailPopup>
      </DialogContent>
      <DialogActions>
        <CustomButton
          label="Cancel"
          type="outlined"
          color="primary"
          size="large"
          onClick={onCancel}
        />
        <CustomButton
          label="Save"
          width="100px"
          type="contained"
          color="primary"
          size="large"
          onClick={() => onSave(measuredValues)}
        />
      </DialogActions>
    </>
  );
};

export default DetailPopup;
