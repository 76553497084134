export const conversationFormula = (
  x: number, // 측정 경도치
  deviceType: "hybrid" | "strength",
  measuredType?: "settingDegree" | "strength"
) => {
  let y = 0;
  if (deviceType === "hybrid") {
    if (measuredType === "settingDegree") y = 0.88627 + 0.71124 * x;
    else if (measuredType === "strength")
      y = -1.16172 + 0.27929 * Math.exp((x + 15.037) / 28.5129);
  } else if (deviceType === "strength")
    y = -0.4444 + 0.000596579 * Math.exp((x + 109.37) / 20.2469);

  return y;
};
