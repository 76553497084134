import { ReactNode } from "react";
import { create } from "zustand";

type ErrorCodeStoreType = {
  errorCode?: number;
  setErrorCode: (errorCode: number | undefined) => void;
};

const useErrorCodeStore = create<ErrorCodeStoreType>((set) => ({
  errorCode: undefined,
  setErrorCode: (errorCode: number | undefined) =>
    set({ errorCode: errorCode }),
}));

export const updateErrorCode = (errorCode: number | undefined) => {
  useErrorCodeStore.setState({
    errorCode: errorCode,
  });
};

export default useErrorCodeStore;
