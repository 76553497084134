import React, { useEffect } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import dayjs, { Dayjs } from "dayjs";

type CustomTimePickerProps = {
  id: string;
  value: string;
  width?: string;
  label?: string;
  placeholder?: string;
  helpMessage?: string;
  disabled?: boolean;
  readonly?: boolean;
  required?: boolean;
  handleChange: (value: string | null) => void;
};

const CustomTimePicker = ({
  id,
  value,
  width,
  label,
  placeholder,
  helpMessage,
  disabled,
  readonly,
  required,
  handleChange,
}: CustomTimePickerProps) => {
  const onChange = (value: Dayjs | null) => {
    if (value) {
      const formatted = dayjs(value).format("YYYY-MM-DDTHH:mm"); // 원하는 포맷으로 변환
      handleChange(formatted); //
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <TimePicker
        className="date-picker-wrap"
        disabled={disabled}
        readOnly={readonly}
        format="hh:mm"
        slotProps={{
          textField: {
            id: id,
            label: label,
            helperText: helpMessage,
            size: "small",
            sx: { backgroundColor: "#FFFFFF", width: width ?? "160px" },
            placeholder: placeholder ?? "시간",
            required: required,
          },
        }}
        value={dayjs(value)}
        onChange={onChange}
      />
    </LocalizationProvider>
  );
};

export default CustomTimePicker;
