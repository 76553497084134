import { userInfo } from "os";
import { create } from "zustand";
import { persist } from "zustand/middleware";

type UserInfoType = {
  loginId: string;
  userId: string;
};

type AuthStoreType = {
  isLogin: boolean;
  userInfo: UserInfoType;
  setIsLogin: (isLogin: boolean) => void;
  setUserInfo: (userInfo: UserInfoType) => void;
  resetAuthStore: () => void;
};

const useAuthStore = create<AuthStoreType>()(
  persist(
    (set) => ({
      isLogin: false,
      userInfo: { loginId: "", userId: "" },
      setIsLogin: (isLogin: boolean) => set(() => ({ isLogin: isLogin })),
      setUserInfo: (userInfo: UserInfoType) =>
        set(() => ({ userInfo: userInfo })),
      resetAuthStore: () =>
        set(() => ({
          isLogin: false,
          userInfo: { loginId: "", userId: "" },
        })),
    }),
    { name: "AuthStore" }
  )
);

export default useAuthStore;
