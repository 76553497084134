import { updateAlertMessage } from "../stores/useAlertStore";
import { updateErrorCode } from "../stores/useErrorCodeStore";

export const checkErrorType = (url: string, status: number) => {
  if (!url) return;
  const [_, apiType, endPoint] = url.split("/");

  if (status === 401) updateErrorCode(401);

  if (status)
    switch (apiType) {
      case "user":
        if (endPoint === "login")
          updateAlertMessage({
            type: "error",
            message: "아이디 또는 비밀번호가 일치하지 않습니다.",
            timeout: 3000,
          });
        break;
      default:
        updateAlertMessage({
          type: "error",
          message: "예기치 못한 오류가 발생했습니다. 다시 시도해주세요.",
          timeout: 3000,
        });
    }
};

export const returnData = () => {};
