import { ReactNode } from "react";
import { create } from "zustand";

type AlertType = "success" | "info" | "error" | "warning";

export type AlertButtonType = {
  label?: string;
  icon?: ReactNode;
  onClick: () => void;
};

type ActiveAlertProps = {
  type: AlertType;
  message: string;
  timeout: number;
  button?: AlertButtonType;
};
type AlertStoreType = {
  alertCloseTrigger: number;
  alertInstantClose: boolean;
  alertMessage: string;
  alertTimeout: number;
  alertType: AlertType;
  alertButton?: AlertButtonType;
  alertVisible: boolean;
  activeAlert: (props: ActiveAlertProps) => void;
  closeAlert: (instant?: boolean) => void;
  resetAlert: () => void;
};

const useAlertStore = create<AlertStoreType>((set) => ({
  alertCloseTrigger: 0,
  alertInstantClose: false,

  alertMessage: "",
  alertTimeout: 3000,
  alertType: "success",
  alertButton: undefined,
  alertVisible: false,

  activeAlert: ({ type, message, timeout, button }: ActiveAlertProps) => {
    const lines = message.split("\n");
    if (lines.length > 2) {
      message = lines.slice(0, 2).join("\n");
      console.error(
        "Please do not include more than 2 line breaks in your alert."
      );
    }
    set({
      alertMessage: message,
      alertTimeout: timeout,
      alertType: type,
      alertButton: button || undefined,
      alertVisible: true,
    });
  },
  closeAlert: (instant?: boolean) => {
    set((state) => ({
      alertCloseTrigger: state.alertCloseTrigger + 1,
      alertInstantClose: instant || false,
      alertButton: undefined,
    }));
  },
  resetAlert: () => {
    set({
      alertMessage: "",
      alertTimeout: 3000,
      alertType: "success",
      alertButton: undefined,
      alertVisible: false,
    });
  },
}));

export const updateAlertMessage = ({
  type,
  message,
  timeout,
  button,
}: ActiveAlertProps) => {
  useAlertStore.setState({
    alertMessage: message,
    alertTimeout: timeout,
    alertType: type,
    alertButton: button || undefined,
    alertVisible: true,
  });
};

export default useAlertStore;
