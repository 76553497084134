import { Paper } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { StyledImagePreview } from "./StyledImagePreview";
import PhotoOutlinedIcon from "@mui/icons-material/PhotoOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import RemoveOutlinedIcon from "@mui/icons-material/RemoveOutlined";

import "pdfjs-dist/web/pdf_viewer.css";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
import { Document, Page } from "react-pdf";

import { pdfjs } from "react-pdf";
import CustomFloatingButton from "../input/button/CustumFloatingButton/CustomFloatingButton";
import useDialogStore from "../../stores/useDialogStore";
import CustomPopup from "../popup/CustomPopup";
import Marker from "./marker/Marker";
import DetailPopup from "./detailPopup/DetailPopup";
import { MarkerDataType, MarkerType } from "../../types/common/commonTypes";
import dayjs from "dayjs";

// PDF.js 워커 파일 설정
pdfjs.GlobalWorkerOptions.workerSrc = `${process.env.PUBLIC_URL}/pdf.worker.js`;
type ImagePreviewProps = {
  file: File | null;
  markers: MarkerType[];
  setMarkers: (markers: MarkerType[]) => void;
  handleClick: () => void;
};

const ImagePreview = ({
  file,
  markers,
  setMarkers,
  handleClick,
}: ImagePreviewProps) => {
  const { openDialog, closeDialog } = useDialogStore();

  const containerRef = useRef<HTMLDivElement>(null); // 컨테이너 참조
  const layerRef = useRef<HTMLDivElement>(null);

  const [listCount, setListCount] = useState(1);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedMarker, setSelectedMarker] = useState<MarkerType | null>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isMarkerMode, setIsMarkerMode] = useState(false);
  const [containerSize, setContainerSize] = useState({
    width: 1,
    height: 1,
  });
  const [imageSize, setImageSize] = useState({
    width: 1,
    height: 1,
  });
  const [scale, setScale] = useState(1);
  const [fileType, setFileType] = useState<"img" | "pdf" | "">("");
  const [previewSrc, setPreviewSrc] = useState("");

  const imageRef = useRef<HTMLDivElement>(null);

  const handleFileChange = async () => {
    if (!file) {
      setPreviewSrc("");
      setFileType("");
      return;
    }
    const fileType = file.type;

    if (fileType.startsWith("image/")) {
      const reader = new FileReader();
      const img = new Image();

      reader.onload = (e) => {
        if (e.target) {
          img.src = e.target.result as string;
          setPreviewSrc(e.target.result as string);
        }
      };

      img.onload = () => {
        setImageSize({
          width: img.naturalWidth,
          height: img.naturalHeight,
        });
      };

      reader.readAsDataURL(file); // 파일을 DataURL로 읽음
      setFileType("img");
    } else if (fileType === "application/pdf") {
      setPreviewSrc("");
      setFileType("pdf");
    }
  };

  const onLoadSuccess = (page: any) => {
    // PDF 페이지의 원본 크기 설정
    const { width, height } = page.getViewport({ scale: 1 });
    setImageSize({ width, height });
  };

  const calculateScale = () => {
    const { width: containerWidth, height: containerHeight } = containerSize;
    const { width: pdfWidth, height: pdfHeight } = imageSize;

    const widthScale = containerWidth / pdfWidth;
    const heightScale = containerHeight / pdfHeight;

    setScale(Math.min(widthScale, heightScale));
  };

  const handleImageClick = (event: React.MouseEvent) => {
    if (!isMarkerMode) return;

    event.stopPropagation();
    const layer = layerRef.current;
    const layerRect = layer?.getBoundingClientRect();

    if (!layerRect) return;

    // 클릭한 위치를 이미지의 비율로 계산
    const xRatio = (event.clientX - layerRect.left) / layerRect.width;
    const yRatio = (event.clientY - layerRect.top) / layerRect.height;

    const newObj = {
      isTemp: true,
      orderNum: listCount + 1,
      xRatio,
      yRatio,
      data: {
        pouringTime: dayjs(new Date()).format("YYYY-MM-DD"),
        concreteTemperature: "",
        concreteStrength: "",
        convertedMPa: 0,
      },
    };

    setMarkers([...markers, newObj]);
    setSelectedMarker(newObj);

    setListCount(listCount + 1);
    setIsMarkerMode(false);

    setIsPopupOpen(true);
  };

  const handleMenuOpen = (
    e: React.MouseEvent<HTMLButtonElement>,
    marker: MarkerType
  ) => {
    e.stopPropagation();

    setSelectedMarker(marker);
    setAnchorEl(e.currentTarget);
  };

  const handleMenuClose = () => {
    setSelectedMarker(null);
    setAnchorEl(null);
  };

  const onMarkerDelete = (index: number) => {
    setAnchorEl(null);

    openDialog({
      size: "small",
      title: "삭제",
      state: "info",
      content: "마커를 삭제하시겠습니까?",
      button: {
        label: "Okay",
        onClick: () => {
          setMarkers(markers.filter((_, i) => i !== index));
          setSelectedMarker(null);
          closeDialog();
        },
      },
      secondButton: {
        label: "Cancel",
        onClick: () => closeDialog(),
      },
    });
  };

  useEffect(() => {
    const updateContainerSize = () => {
      if (containerRef.current) {
        const { offsetWidth, offsetHeight } = containerRef.current;
        setContainerSize({ width: offsetWidth, height: offsetHeight });
      }
    };

    updateContainerSize();
    window.addEventListener("resize", updateContainerSize);

    return () => {
      window.removeEventListener("resize", updateContainerSize);
    };
  }, []);

  useEffect(() => {
    handleFileChange();
  }, [file]);

  useEffect(() => {
    calculateScale();
  }, [containerSize, imageSize]);

  return (
    <>
      <StyledImagePreview
        ref={containerRef}
        onClick={() => {
          if (selectedMarker === null) handleClick();
        }}
      >
        <Paper
          variant="elevation"
          square={false}
          elevation={3}
          className="wh-100"
        >
          {fileType === "img" ? (
            <div
              className="image-box"
              style={{
                backgroundImage: `url("${previewSrc}")`,
              }}
            ></div>
          ) : fileType === "pdf" ? (
            <div className="pdf-wrap" ref={imageRef}>
              <Document file={file}>
                <Page
                  pageNumber={1}
                  scale={scale}
                  onLoadSuccess={onLoadSuccess}
                />
              </Document>
            </div>
          ) : (
            <p className="placeholder-text">
              <PhotoOutlinedIcon fontSize="large" />
            </p>
          )}

          <div
            ref={layerRef}
            className="layer"
            onClick={handleImageClick}
            style={{
              width: `${imageSize.width * scale}px`,
              height: `${imageSize.height * scale}px`,
            }}
          >
            {markers.map((marker, index: number) => {
              const layer = layerRef.current;
              const layerRect = layer?.getBoundingClientRect();

              if (!layerRect) return null;

              const x = marker.xRatio * layerRect.width;
              const y = marker.yRatio * layerRect.height;

              return (
                <Marker
                  key={`${x}-${y}`}
                  x={x}
                  y={y}
                  marker={marker}
                  isSelected={selectedMarker?.orderNum === marker.orderNum}
                  anchorEl={anchorEl}
                  togglePopup={(isOpen) => {
                    setIsPopupOpen(isOpen);
                    setAnchorEl(null);
                  }}
                  handleMenuOpen={(e) => handleMenuOpen(e, marker)}
                  onMarkerDelete={() => onMarkerDelete(index)}
                  handleMenuClose={handleMenuClose}
                />
              );
            })}
          </div>

          {file && (
            <CustomFloatingButton
              tooltip={{ label: "Add Point", direction: "top" }}
              className="image-edit-btn"
              size="small"
              color="primary"
              aria-label="edit"
              icon={isMarkerMode ? <RemoveOutlinedIcon /> : <AddOutlinedIcon />}
              onClick={(e) => {
                e.stopPropagation();
                setIsMarkerMode(!isMarkerMode);
              }}
            />
          )}
        </Paper>
      </StyledImagePreview>
      <CustomPopup
        isOpen={isPopupOpen}
        children={
          <DetailPopup
            selectedMarker={selectedMarker?.data}
            onCancel={() => {
              setIsPopupOpen(false);
              setSelectedMarker(null);
              setMarkers(markers.filter((item) => !item.isTemp));
            }}
            onSave={(markerData: MarkerDataType) => {
              setMarkers(
                markers.map((item) => {
                  if (item.orderNum === selectedMarker?.orderNum) {
                    return { ...item, isTemp: false, data: markerData };
                  } else {
                    return { ...item };
                  }
                })
              );

              setSelectedMarker(null);
              setIsPopupOpen(false);
            }}
          />
        }
        title="측정 결과 기록"
        setIsOpen={setIsPopupOpen}
      />
    </>
  );
};

export default ImagePreview;
